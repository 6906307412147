<template>
    <div class="animated fadeIn wit-billing">
        <b-tabs fill v-model="tabIndex" class="wit-tabs">
            <b-tab :title="tabs[0]">
                <BillingSubscription :details="details" :invoices="invoices" :loading.sync="upcomingLoading" />
            </b-tab>
            <b-tab :title="tabs[1]">
                <BillingDetails :details.sync="details" :loading.sync="detailsLoading" />
            </b-tab>
            <b-tab :title="tabs[2]">
                <BillingInvoices :invoices.sync="invoices" :loading.sync="invoicesLoading" />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import VueNotifications from 'vue-notifications'

export default {
    components: {
        BillingDetails: () => import('./Details.vue'),
        BillingInvoices: () => import('./Invoices.vue'),
        BillingSubscription: () => import('./Subscription.vue'),
    },
    notifications: {
        accountUpdated: {
            type: VueNotifications.types.success,
            title: 'Billing Account has been successfully updated.',
        },
        accountDeactivated: {
            type: VueNotifications.types.success,
            title: 'Billing Account has been successfully deactivated.',
        },
        accountActivated: {
            type: VueNotifications.types.success,
            title: 'Billing Account has been successfully activated.',
        },
    },
    data() {
        return {
            tabIndex: 0,
            details: null,
            invoices: [],
            tabs: ['Subscription', 'Billing Details', 'Invoices'],
            upcomingLoading: true,
            detailsLoading: true,
            invoicesLoading: true,
        }
    },
    computed: {
        activeBillingAccount: {
            get() {
                return this.$store.state.billing.active
            },
        },
        loading() {
            return this.upcomingLoading || this.detailsLoading || this.invoicesLoading
        },
    },
    mounted() {
        if (this.activeBillingAccount) {
            this.$store.commit('loading/PROCESSING', `Fetching your billing info...`)

            if (this.$route.query.t) {
                this.onRouteChange()
            } else {
                this.pushQuery()
            }
        } else {
            this.$projectRouter.push('/dashboard')
        }
    },
    watch: {
        tabIndex() {
            this.pushQuery()
        },
        $route() {
            this.onRouteChange()
        },
        loading(value) {
            if (value) {
                this.$store.commit('loading/PROCESSING', `Fetching your billing info...`)
            } else {
                this.$store.commit('loading/PROCESSED')
            }
        },
    },
    methods: {
        pushQuery() {
            if (this.$route.query.t !== this.tabs[this.tabIndex].toLowerCase().replace(/\s+/g, '-')) {
                this.$router.push({
                    query: {...this.$route.query, t: this.tabs[this.tabIndex].toLowerCase().replace(/\s+/g, '-')},
                })
            }
        },

        onRouteChange() {
            this.checkRoute()

            const tabIndexFound = this.tabs.findIndex(
                el => el.toLowerCase().replace(/\s+/g, '-') === this.$route.query.t
            )

            if (tabIndexFound > -1) {
                this.tabIndex = tabIndexFound
            }
        },

        async checkRoute() {
            const {accountUpdated, accountActivated, ...rest} = this.$route.query

            if (accountUpdated) {
                this.accountUpdated()
                this.$router.replace({query: {...rest}})
            }

            if (accountActivated) {
                this.accountActivated()
                this.$router.replace({query: {...rest}})
            }
        },
    },
}
</script>

<style lang="scss"></style>
